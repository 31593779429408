






























import "reflect-metadata";
import { Vue, Component, Prop, Model } from "vue-property-decorator";
import { Big, bnToBig } from "@avalabs/avalanche-wallet-sdk";
//@ts-ignore
import { BigNumInput } from "@avalabs/vue_components";
import { BN } from "avalanche";
import { priceDict } from "../../store/types";

@Component({
  components: {
    BigNumInput,
  },
})
export default class DjtxInput extends Vue {
  @Model("change", { type: Object }) readonly amount!: BN;

  @Prop({
    default: null,
  })
  max?: BN | null;

  @Prop() balance?: Big | null;

  maxOut(ev: MouseEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    //@ts-ignore
    this.$refs.amt_in.maxout();
  }

  amount_in(val: BN) {
    this.$emit("change", val);
  }

  get amountUSD(): Big {
    let usdPrice = this.priceDict.usd;
    let amount = bnToBig(this.amount, 9);
    let usdBig = amount.times(usdPrice);
    return usdBig;
  }

  get priceDict(): priceDict {
    return this.$store.state.prices;
  }
}
