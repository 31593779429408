













































import { Vue, Component, Prop } from "vue-property-decorator";
//@ts-ignore
import { BigNumInput } from "@avalabs/vue_components";
import { BN } from "avalanche";
import EVMAssetDropdown from "@/components/misc/EVMInputDropdown/EVMAssetDropdown.vue";
import Erc20Token from "@/js/Erc20Token";
import Big from "big.js";
import { WalletType } from "@/js/wallets/types";

import { bnToBig } from "@/helpers/helper";
import EVMTokenSelectModal from "@/components/modals/EvmTokenSelect/EVMTokenSelectModal.vue";
import { iErc721SelectInput } from "@/components/misc/EVMInputDropdown/types";
import ERC721View from "@/components/misc/ERC721View.vue";
import ERC721Token from "@/js/ERC721Token";

@Component({
  components: {
    ERC721View,
    EVMTokenSelectModal,
    EVMAssetDropdown,
    BigNumInput,
  },
})
export default class EVMInputDropdown extends Vue {
  token: Erc20Token | "native" = "native";
  isCollectible = false;
  collectible: iErc721SelectInput | null = null;
  @Prop({ default: false }) disabled!: boolean;
  @Prop() gasPrice!: BN; // in wei
  @Prop({ default: 21000 }) gasLimit!: number;
  amt = new BN(0);

  $refs!: {
    bigIn: BigNumInput;
    dropdown: EVMAssetDropdown;
  };

  clear() {
    this.$refs.dropdown.clear();
  }

  get usd_val(): Big {
    if (this.token != "native") return Big(0);

    let price = this.$store.state.prices.usd;
    let big = bnToBig(this.amt, 18);
    return big.mul(Big(price));
  }

  get max_amount(): BN {
    // Subtract gas
    if (this.isNative) {
      let limit = new BN(this.gasLimit);
      let fee = limit.mul(this.gasPrice);
      return this.balanceBN.sub(fee);
    } else {
      return this.balanceBN;
    }
  }

  get isNative() {
    return this.token === "native";
  }
  get denomination(): number {
    if (this.isNative) {
      return 18;
    } else {
      return parseInt((this.token as Erc20Token).data.decimals as string);
    }
  }

  get stepSize(): BN {
    if (this.denomination > 3) {
      let powBN = new BN(10).pow(new BN(this.denomination - 2));
      // let stepNum = Math.pow(10, this.denomination - 2)
      return powBN;
    } else {
      let powBN = new BN(10).pow(new BN(this.denomination));
      // let stepNum = Math.pow(10, this.denomination)
      return powBN;
    }
  }

  get asset_now() {
    return {
      denomination: 2,
    };
  }

  get placeholder(): string {
    let deno = this.denomination;
    let res = "0";
    if (deno > 2) {
      res = "0.00";
    }
    return res;
  }

  maxOut() {
    // @ts-ignore
    this.$refs.bigIn.maxout();
  }

  get djtxBalanceBN(): BN {
    let w: WalletType | null = this.$store.state.activeWallet;
    if (!w) return new BN(0);
    return w.ethBalance;
  }

  get djtxBalance(): Big {
    return bnToBig(this.djtxBalanceBN, 18);
  }

  get balance(): Big {
    if (this.token === "native") {
      return this.djtxBalance;
    }
    return this.token.balanceBig;
  }

  // The available balance of the selected asset
  get balanceBN(): BN {
    if (this.token === "native") {
      return this.djtxBalanceBN;
    }
    return this.token.balanceBN;
  }

  setToken(token: "native" | Erc20Token) {
    this.$refs.dropdown.select(token);
  }

  setErc721Token(token: ERC721Token, tokenId: string) {
    this.$refs.dropdown.selectERC721({
      token: token,
      id: tokenId,
    });
  }

  onAssetChange(token: Erc20Token | "native") {
    this.isCollectible = false;
    this.token = token;
    this.$nextTick(() => {
      this.$refs.bigIn.clear();
    });
    this.$emit("tokenChange", token);
  }

  onCollectibleChange(val: iErc721SelectInput) {
    this.isCollectible = true;
    this.collectible = val;
    this.$emit("collectibleChange", val);
  }

  amount_in(amt: BN) {
    this.amt = amt;
    this.$emit("amountChange", amt);
  }
}
