<template>
  <div class="copyButs" @click="copy">
    <!--        <fa icon="copy"></fa>-->
    <img class="reg" v-if="$root.theme === 'day'" src="/img/copy_night.svg" />
    <img class="reg" v-else src="/img/copy_night.svg" />
    <p class="text">Copy</p>
    <input ref="copytext" :value="value" />
  </div>
</template>
<script>
export default {
  props: {
    value: String,
  },
  methods: {
    copy() {
      let copytext = this.$refs.copytext;
      copytext.select();
      copytext.setSelectionRange(0, 99999);

      document.execCommand("copy");
      this.$store.dispatch("Notifications/add", {
        title: " Copied",
        message: "Copied to clipboard.",
      });
    },
  },
};
</script>
<style scoped lang="scss">
.copyButs {
  display: flex;
  width: max-content;
  align-items: center;
  cursor: pointer;
}
.copyButs input {
  width: 1px;
  position: absolute;
  opacity: 0;
}
.text {
  user-select: none;
  pointer-events: none;
  margin-left: 8px !important;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
}

img.reg {
  max-height: 16px;
  height: 15px !important;
  max-width: 100%;
  width: 16px !important;
  margin-right: 0px !important;
  object-fit: contain;
  mix-blend-mode: unset !important;
  display: block;
  vertical-align: middle;
}

img {
  max-height: 18px;
  object-fit: contain;
}
input {
  pointer-events: none;
  user-select: none;
  width: 100% !important;
}
button {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
}
</style>
