






import "reflect-metadata";
import { Vue, Component, Prop, Watch, Model } from "vue-property-decorator";
import { CurrencyType } from "@/components/misc/CurrencySelect/types";

@Component
export default class CurrencySelect extends Vue {
  @Model("change", { type: String }) readonly currency!: CurrencyType;
  setType(val: CurrencyType) {
    this.$emit("change", val);
  }
}
